// //import networks1 from './Artwork/content-section1/networks1.png';
// import networks2 from './Artwork/content-section1/networks2.png';
// import networks3 from './Artwork/content-section1/networks3.png';
// import networks4 from './Artwork/content-section1/networks4.png';
import './App.css';

function App() {
  return (
<body>
  <header className="main-header">
    <nav className="main-nav">
      <ul>
      </ul>
    </nav>
    <h1 className="full-name">Ava Frances Claxton</h1>
  </header>

<section className= "Photography-section">
    <p className= "work_in_progress">Hello! Welcome to www.avaclaxton.com! This is a work in progress coding project where I will be publishing my artwork. I hope you enjoy watching this website evolve as I learn, create, and update.</p>
    <h2>Photography</h2>
  <section className="content-section1">
      <h3 className="section-header">Networks & The Unity of Life</h3>
      <img className="project1" src="Artwork/content-section1/networks1.png"/>
      <img className="project1" src="Artwork/content-section1/networks2.png"/>
      <img className="project1" src="Artwork/content-section1/networks3.png"/>
      <img className="project1" src="Artwork/content-section1/networks4.png"/>
      
  </section>
  
  <section className="content-section2">
      <h3 className="section-header">REM</h3>
      <p>Installation visualizing dream sleep as a projection on the bedroom environment.</p>

      <img className="project2" src="Artwork/content-section2/sleep1.png"/>
      <img className="project2" src="Artwork/content-section2/sleep2.png"/>
  </section>

  <section className="artist-statement">
    <h2>Artist Statement</h2>
    <p>I am most interested in my artistic practice as a form of scientific exploration, and strive to use the concepts I have learned in cognition and neurobiology to understand a viewer’s sensation and perception of my work. I use painting, drawing, photography, sculpture, and video installation to express these ideas—considering the visual dynamics of each medium when deciding how to best illustrate my thought and experimentation. Recently, I have been interested in the fundamental importance of network structures, which surround us in the natural world, and operate within our own minds. Decoding how the form of these networks generates function has made me consider this theme of “form and function” in non-biological spaces, such as in architecture and how humans organize socially. In this way, the interconnected network structure reaches beyond the physical to the social, technological, and metaphorical. I am very interested in illustrating this interconnectedness, and my current art exists as a documentation of my exploration of these concepts. </p>
  </section>
</section>

</body>
  );
}

export default App;
